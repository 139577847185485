import React from 'react'
import {Inner} from '../components/responsive/'

export default props => <Inner
  size='1.5em'
  as='h3'
  color='#7f7f7f'
  marginBottom='1em'
  marginTop='2em'>
{props.children}
</Inner>
